import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { en, ko } from 'vuetify/es5/locale';

import '@mdi/font/css/materialdesignicons.css'
import '@/assets/scss/main.scss'

Vue.use(Vuetify);

export default new Vuetify({
    // 언어 설정
    lang: {
        locales: { ko, en },
        current: 'ko', // 한국어
    },
    theme: {
        options: { customProperties: true },
        defaultTheme: 'light',
        dark: false,
        themes: {
            light: {
                yellow: "#fdc601",
                subtitle: '#797878',
                primary: '#205497',
                secondary: '#0455b6',
                accent: '#000000',
                text: '#121212',
                textBackground: '#c2d3fd',
                book: '#521800',
                bg_1: '#f1f5ff',
                bg_2: '#cfd6f1'
            },
            dark: {
                yellow: "#fdc601",
                subtitle: '#797878',
                primary: '#174887',
                secondary: '#0455b6',
                accent: '#ffffff',
                text: '#f1f1f1',
                cardText: '#000000',
                cardTextBackground: '#454559',
                book: '#702100',
                bg_1: '#2c2c2c',
                bg_2: '#7f7f93'
            }
        }
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        values: {
            structure: 'mdi-molecule',
            docking: 'mdi-vector-union',
            clustering: 'mdi-chart-bubble',
            pharmacophore: 'mdi-grain',
            solutionBuilder: 'mdi-blur',
            mdiStarOutline: 'mdi-star-outline',
            mdiStar: 'mdi-star',
            mdiMinus: 'mdi-minus',
            mdiStarHalfFull: 'mdi-star-half-full',
            mdiVideoBox: 'mdi-video-box'
        }
    },
    defaults: {
        global: {
            font: {
                family: 'Nanum Gothic',
            },
        },
    },
});

