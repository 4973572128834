import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import api from './api'
import util_common from './utils/util_common'
import common_auth from './utils/common_auth'
import { i18n } from "@/locales/i18n";

import vuetify from './plugins/vuetify';

// Service Worker
import './registerServiceWorker'


import './router/permission' // permission control
import * as filters from './filters' // global filters

// bootStrap
import 'bootstrap';

// style
import '@/assets/scss/variables.scss'
import '@/assets/scss/main.scss'
import '@/assets/css/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css'

import VueCookies from "vue-cookies";

//쿠키를 사용한다.
Vue.use(VueCookies);
//쿠키의 만료일은 7일이다. (글로벌 세팅)
Vue.$cookies.config("7d");

// vuetify dialog Alert, Snackbar
import 'vuetify-dialog/dist/vuetify-dialog.css'
import VuetifyDialog from 'vuetify-dialog'
Vue.use(VuetifyDialog, {context: {vuetify}})

// vuetify Phone Input
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// register global utility filters
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    api,
    vuetify,
    i18n,
    util_common,
    common_auth,
    render: h => h(App)
})
