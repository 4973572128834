import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import adminRouter from "@/router/modules/admin";
import commonRouter from "@/router/modules/common";
import en9doorRouter from '@/router/modules/en9door'
import paymentRouter from "@/router/modules/payment";


const createRouter = () => new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'MainLayout',
      component: () => import('@/layout/sideLayout'),
      children: [
        {path: '/', name: 'Main', component: () => import(/* webpackChunkName: "common" */'@/views/main/main'), meta: {title: 'English Door'}},
        {path: 'login', name:'login', component: () => import(/* webpackChunkName: "common" */'@/views/login/index'), hidden: true},
        ...commonRouter,
        ...en9doorRouter,
        ...paymentRouter,
        ...adminRouter
        // templateRouter,
      ]
    },

    {path: '/oauth/redirect', component: () => import('@/views/login/redirect'), hidden: true},
    {path: '/logout', component: () => import('@/views/login/logout'), hidden: true},
    // 404 page must be placed at the end !!!
    {path: '*', redirect: '/404', hidden: true}
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
