import apiCommon from "@/api/modules/api-common";
import {methods} from '@/api/axios-en9door'

export default {
    state: {
        book: [],
        content: [{
            contentId:'',
            contentName:'',
            createdAt:'',
            description:'',
            useFlag:''
        }],
        cover: []
    },
    getters: {
        contentState: state => state,
    },
    mutations: {
        SET_COVER: (state, cover) => {
            state.cover = cover
        },
        SET_CONTENT: (state, content) => {
            state.content = content
        },
        SET_BOOK: (state, book) => {
            state.book = book
        },
    },
    actions: {
        fetchContent ({commit}) {
            return apiCommon.getContent()
                .then(res => {
                    res.content = res.content.filter(content => content.useFlag ==='Y')
                    commit('SET_CONTENT', res.content)
                })
        },
        fetchCover ({commit}) {
            return methods.get(`/ed/common/content/list-books?prefix=website/book/cover/Sample`)
                .then(res => {
                    res.contentName = 'Book List'
                    commit('SET_COVER', res)
                })
        },
        fetchBook ({commit}) {
            return apiCommon.getBook()
                .then(res => commit('SET_BOOK', res.book))
        },
    }
}
