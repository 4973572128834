import Vue from 'vue'
import util_common from "@/utils/util_common";


export const common_date = {
  async toDay (format) {
    return new Promise((resolve, reject)=> {
      let rtnDate = ''
      Vue.prototype.$getMethod('/common/server/date')
        .then(async (res) => {
          rtnDate = res.data.date
          const yyyy = rtnDate.substring(0, 4)
          const mm = rtnDate.substring(4, 6)
          const dd = rtnDate.substring(6, 8)
          const hh = rtnDate.substring(8, 10)
          const mi = rtnDate.substring(10, 12)
          const ss = rtnDate.substring(12, 14)
          if (format === 'YYYY/MM/DD') {
            resolve(`${yyyy}/${mm}/${dd}`)
          } else if (format === 'MM/DD/YYYY') {
            resolve(`${mm}/${dd}/${yyyy}`)
          } else if (format === 'DD/MM/YYYY') {
            resolve(`${dd}/${mm}/${yyyy}`)
          } else if (format === 'YYYY/MM/DD HH:MI:SS') {
            resolve(`${yyyy}/${mm}/${dd} ${hh}:${mi}:${ss}`)
          } else if (format === 'MM/DD/YYYY HH:MI:SS') {
            resolve(`${mm}/${dd}/${yyyy} ${hh}:${mi}:${ss}`)
          } else if (format === 'DD/MM/YYYY HH:MI:SS') {
            resolve(`${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`)
          } else if (format === 'YYYY/MM') {
            resolve(`${yyyy}/${mm}`)
          } else {
            resolve(`${yyyy}-${mm}-${dd}`)
          }
        })
        .catch(() => {
          resolve('')
        })
    })
  },
  getTodayDateString(){
    const now = new Date();
    if (now.getHours() < 8 || now.getHours() > 23) now.setHours(8)
    return parseDate(now, '{y}-{m}-{d}T{h}:{i}:{s}')
  },
  getTomorrowDateString() {
    const now = new Date();
    now.setDate(now.getDate() + 1)
    if (now.getHours() < 8 || now.getHours() > 23) now.setHours(8)
    return parseDate(now, '{y}-{m}-{d}T{h}:{i}:{s}')
  },
  // getDateFromString (value) {
  //   if (Vue.prototype.$isNull(value)) return ''
  //
  //   value = value.replace(/-/g, '').replace(/\//g, '').replace(/:/g, '').replace(/ /g, '')
  //
  //   const userDateFormat = store.getters.getDateFormat
  //   const formatDate = Vue.prototype.$changeFormatDate(value, 'YYYY-MM-DD', userDateFormat)
  //   return formatDate
  // },
  // userDateFormat (value) {
  //   if (util_common.isNull(value)) return value
  //   return this.getDateFromString(value)
  // },
  formatDate (date) {
    if (date === null) return ''

    const formatDate = String(date.getFullYear()) + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
    return formatDate
  },
  userDateFormat (value) {
    if (util_common.isNull(value)) return value
    return this.getDateFromString(value)
  },
  changeFormatDate (value, fromFormat, toFormat) {
    const param = value.replace(/-/g, '').replace(/\//g, '')
    var yyyy = 0
    var mm = 0
    var dd = 0

    if (fromFormat === 'DD/MM/YYYY') {
      yyyy = param.substring(4, 8)
      mm = param.substring(2, 4)
      dd = param.substring(0, 2)
    } else if (fromFormat === 'MM/DD/YYYY') {
      yyyy = param.substring(4, 8)
      mm = param.substring(0, 2)
      dd = param.substring(2, 4)
    } else {
      yyyy = param.substring(0, 4)
      mm = param.substring(4, 6)
      dd = param.substring(6, 8)
    }
    let formatVal = ``

    if (toFormat === 'DD/MM/YYYY') {
      formatVal = `${dd}/${mm}/${yyyy}`
    } else if (toFormat === 'MM/DD/YYYY') {
      formatVal = `${mm}/${dd}/${yyyy}`
    } else if (toFormat === 'YYYY/MM/DD') {
      formatVal = `${yyyy}/${mm}/${dd}`
    } else {
      formatVal = `${yyyy}-${mm}-${dd}`
    }

    return formatVal
  },
  dateLocale () {
    const userLang = window.sessionStorage.getItem('$userLangType')

    if (userLang === 'KO') {
      return {
        /* starting with Sunday */
        days: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
        daysShort: '일_월_화_수_목_금_토'.split('_'),
        months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        firstDayOfWeek: 1
      }
    } else {
      return {
        /* starting with Sunday */
        days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
        pluralDay: 'days'
      }
    }
  },

  parseStringToTime(timeString) {
    if (arguments.length === 0 || !timeString) {
      return null
    }
    let timeParam = timeString.replaceAll(':','')
    let time = new Date()
    time.setHours(timeParam.substring(0, 2), timeParam.substring(2, 4), timeParam.substring(4, 6),)
    return time
  },


  addScheduledDates(assign, attendList, minusDays){

    let newAttendDate = new Date()
    newAttendDate.setDate(newAttendDate.getDate() - minusDays)
    let newAttendList = [...attendList]
    for (let i = 0; i < minusDays + 20; i++) {
      let newAttend = {}

      newAttend = common_date.createAttend(assign, newAttendDate)

      // .indexOf(newAttend.attendDate)
      if( attendList.length === 0) {
        newAttendList.push(newAttend)
      } else if (!attendList.map(attend => attend.attendDate).includes(newAttend.attendDate)){
        newAttendList.push(newAttend)
      }
      newAttendDate.setDate(newAttendDate.getDate() + 1)
    }
    return newAttendList
  },
  createAttend(assign, attendDate){
    const lang = navigator.language;
    let newAttend = {}
    newAttend.assignId = assign.assignId
    newAttend.student = assign.student
    newAttend.course = assign.course
    newAttend.content = assign.content
    newAttend.attendDate = common_date.formatDate(attendDate, 'YYYY-MM-DD')
    newAttend.attendStartTime = common_date.formatDate(attendDate, 'YYYY-MM-DD') + 'T' + assign.classStartTime
    newAttend.attendDay = attendDate.toLocaleString(lang, {  weekday: 'long' }).substring(0,1)
    newAttend.attendId = ''
    newAttend.attendStatus = 'NOCLASS'
    return newAttend
  }
}




export function parseDate(date, cFormat) {
  if (arguments.length === 0 || !date) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  if ((typeof date === 'string')) {
    date = new Date(date)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}


Vue.prototype.$formatDate = common_date.formatDate
Vue.prototype.$parseStringToTime = common_date.parseStringToTime
Vue.prototype.$parseDate = parseDate
Vue.prototype.$addScheduledDates = common_date.addScheduledDates
Vue.prototype.$createAttend = common_date.createAttend
Vue.prototype.$dateLocale = common_date.dateLocale
Vue.prototype.$getTodayDateString = common_date.getTodayDateString
Vue.prototype.$getTomorrowDateString = common_date.getTomorrowDateString
