/** When your routing table is too long, you can split it into small modules **/

const en9doorRouter = [
  // footer
  {path: 'company/company-info', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/company/company_info'),     name: 'CompanyInfo', meta: {title: 'Company Info'}},
  {path: 'company/privacy-policy', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/company/privacy_policy'), name: 'CompanyPrivacyPolicy', meta: {title: 'Privacy Policy'}},
  {path: 'company/use-term', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/company/use_term'),             name: 'CompanyUseTerm', meta: {title: 'Use Term'}},

  // student
  {path: 'student/register', component: () => import(/* webpackChunkName: "en9door_student" */'@/views/en9door/student/s_register'),  name: 'StudentRegister', meta: {title: 'Register'}},
  {path: 'student/assign', component: () => import(/* webpackChunkName: "en9door_student" */'@/views/en9door/student/s_assign'),      name: 's_assign', meta: {title: 'Assign'}},
  {path: 'student/assign/:assign_id', component: () => import(/* webpackChunkName: "en9door_student" */'@/views/en9door/student/s_assign'),      name: 'StudentAssignById', meta: {title: 'Assign'}},
  {path: 'student/review', component: () => import(/* webpackChunkName: "en9door_student" */'@/views/en9door/student/s_review'),      name: 'StudentReview', meta: {title: 'Review'}},

  // teacher
  {path: 'teacher/assigns/', component: () => import(/* webpackChunkName: "en9door_teacher" */'@/views/en9door/teacher/t_assigns'),       name: 'TeacherAssigns', meta: {title: 'Teacher Assigns'}},
  {path: 'teacher/assigns/:assign_id', component: () => import(/* webpackChunkName: "en9door_teacher" */'@/views/en9door/teacher/t_assigns'),       name: 'TeacherAssignById', meta: {title: 'Teacher Assigns'}},
  {path: 'teacher/register', component: () => import(/* webpackChunkName: "en9door_teacher" */'@/views/en9door/teacher/t_register'),                name: 'TeacherRegister', meta: {title: 'Teacher Register'}},
  // {path: 'teacher/attends/today', component: () => import(/* webpackChunkName: "en9door_teacher" */'@/views/en9door/teacher/t_attends-today'),      name: 'TeacherTodayAttend', meta: {title: 'Teacher Today Attend'}},
  // {path: 'teacher/attends/student', component: () => import(/* webpackChunkName: "en9door_teacher" */'@/views/en9door/teacher/t_attends-student'),  name: 'TeacherStudentAttend', meta: {title: 'Teacher Student Attend'}},

  // manager
  {path: 'manager/assigns', component: () => import(/* webpackChunkName: "en9door_manager" */'@/views/en9door/manager/m_assigns'),    name: 'ManagerAssign', meta: {title: 'Manager Assign ', keepAlive: true}},
  {path: 'manager/register', component: () => import(/* webpackChunkName: "en9door_manager" */'@/views/en9door/manager/m_register'),  name: 'ManagerRegister', meta: {title: 'Manager Register ', keepAlive: true}},
  {path: 'manager/accounts', component: () => import(/* webpackChunkName: "en9door_manager" */'@/views/en9door/manager/m_accounts'),  name: 'ManagerAccount', meta: {title: 'Manager Account', keepAlive: true}},
  {path: 'manager/attends', component: () => import(/* webpackChunkName: "en9door_manager" */'@/views/en9door/manager/m_attends'),    name: 'ManagerAttend', meta: {title: 'Manager Attend ', keepAlive: true}},
  {path: 'manager/schedule', component: () => import(/* webpackChunkName: "en9door_manager" */'@/views/en9door/manager/m_schedule'),  name: 'ManagerTeacherSchedule', meta: {title: 'Manager Teacher Schedule', keepAlive: true}},

]


export default en9doorRouter
