import {methods} from '@/api/axios-en9door'

const apiSys = {
  getSysConfLike (code) {
    return methods.get(`ed/common/sys/conf/${code}`)
  },
  getSysI18n (language) {
    return methods.get(`common/sys-i18n?language=` + language)
  },
}

export default apiSys
