const util_device = {
  getDeviceType(){
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
    }
    return "desktop";
  },

  getMobileDeviceType(){

    if(window.navigator.userAgent.match(/iPhone|iPad/i)) {
      return 'ios'
    }else if (window.navigator.userAgent.includes('Android')){
      return 'android'
    } else {
      return 'else'
    }
  }
}

export default util_device
