import Vue from 'vue'

const common_auth = {
  hasRole(user, menuRole){
    return user.accountRoles != null
      ? user.accountRoles.map(ar => ar.roleType).includes(menuRole)
      : false
  },
}
export default common_auth

Vue.prototype.$hasRole = common_auth.hasRole
