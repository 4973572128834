import apiCommon from "@/api/modules/api-common";

export default {
  state: {
    menuInfo: {
      menuList: [],
      btnList: [],
      orgMenuList: [],
      favoriteMenuList: [],
      orgFavoriteMenuList: [],
      tabList: [],
      curmstCd: "",
      currentMenuType: "menu"
    },
    cachedPages: [],
  },
  getters: {
    menu: state => state,
    getCachedPages: state => state.cachedPages,
    getTabList: state => state.menuInfo.tabList,
    getFavoriteMenuList: state => state.menuInfo.favoriteMenuList,
    getOrgMenuList: state => state.menuInfo.orgMenuList,
    getOrgFavoriteMenuList: state => state.menuInfo.orgFavoriteMenuList,
  },
  mutations: {
    addTab: (state, fetchData)  => {
      state.menuInfo.tabList.push(fetchData)
    },
    delTab: (state, mstCd) => {
      const idx = state.menuInfo.tabList.findIndex(v => v.mstCd === mstCd)
      if (idx > -1) state.menuInfo.tabList.splice(idx, 1)
    },
    clearTab: (state)  =>{
      state.menuInfo.tabList = []
    },

    pushCachedPages: (state, fetchData) => {
      if (state.cachedPages.indexOf(fetchData) < 0) {
        state.cachedPages.push(fetchData)
      }
    },
    removeCachedPages: (state, fetchData) => {
      state.cachedPages.splice(state.cachedPages.indexOf(fetchData), 1)
    },
    clearCachedPages: (state) => {
      state.cachedPages = []
    }
  },
  actions: {
    fetchBook ({commit}) {
      return apiCommon.getBook()
        .then(res => commit('SET_BOOK', res.book))
    },
  }
}
