import Vue from 'vue'
import router from './index'
import store from '@/store'
import Cookies from 'vue-cookies/vue-cookies'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style


NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  '/',
  '/login',
  '/oauth/redirect',
  '/main/class-info',
  '/main/guide/call',
  '/main/guide/register',
  '/main/teacher',
  '/main/course',
  '/main/content',
  '/company/company-info',
  '/company/privacy-policy',
  '/company/use-term',
  '/student/review',

  '/board/review',
  '/board/notice'
] // no redirect whitelist

router.beforeEach(async(to, from, next) => {

  NProgress.start()
  document.title = '영어문 - 원어민 화상영어, 전화영어 [English Door]'

  if (!Cookies.get('accessToken') || !Cookies.get('accountId')) {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
  }

  let accessToken = store.getters.accessToken
    ? store.getters.accessToken
    : Cookies.get('accessToken')

  let accountId = store.getters.accountId
    ? store.getters.accountId
    : Cookies.get('accountId')

  let user = store.getters.user
  if (accountId && accessToken) {
    await store.commit('SET_TOKEN', accessToken)
    await store.dispatch('fetchUser')
    user = store.getters.user
    NProgress.done()
  }

  if (accessToken) {
    try {
      if (to.path === '/login') {
        next()      // if is logged in, redirect to the home page
      } else {

        let roles = user.accountRoles && user.accountRoles.length > 0

        if (roles) {
          if (to.matched[1]) {
            store.commit('pushCachedPages', to.matched[1].components.default.name)
          }
          next()
        }
      }
    }
    catch (error) {
      // remove token and go to login page to re-login
      await store.dispatch('logout')
      next(`/login?redirect=${to.path}`)
    }
    NProgress.done()

  }
  else {    /* has no token*/
    if (whiteList.filter(path => path.includes(to.path)).length > 0) {      // in the free login whitelist, go directly
      // next({ ...to, replace: true })
      next()
    } else {      // other pages that do not have permission to access are redirected to the login page.
      await store.commit('SET_EMPTYUSER')
      if (to.path != '/logout') {
        let redirectUrl = to.path != null ? `?redirect=${to.path}` : ''
        next(`/login` + redirectUrl )
      }else {
        next(`/login`)
      }
    }
    NProgress.done()
  }
})

router.afterEach((to, from) => {
  if (to.name === 'login') return




  if (to.matched[0].components.default.name === 'Layout' &&
    !Vue.prototype.$isNull(to.matched[1].components.default.name)
  ) {
    store.commit('pushCachedPages', to.matched[1].components.default.name)
  }
  // !to.name.includes( 'Profile', 'Main')
  const showTabPage = ['Teacher', 'Manager'];
  // tab이 없으면
  if (!Vue.prototype.$isNull(to.name) &&
    showTabPage.some(v => to.name.includes(v)) &&
    store.getters.getTabList.filter(v => v.name === to.name).length < 1)
  {
    // const target = store.getters.getOrgMenuList.filter(v => v.mstCd === to.name)[0]
    const target = to
    if (Vue.prototype.$isNull(target)) return

    // tabList 추가
    store.commit('addTab', {
      to: target.path,
      name: target.name,
      label: target.meta.title,
      mstCd: target.name
    })
  }

  NProgress.done()
})
