import Vue from 'vue'

const baseUrl = `${Vue.prototype.$API_HOST}/pdf`

const apiPdf = {
  getAttendSheetPopup(assignId, isFake) {
    Vue.prototype.$getMethod(`${baseUrl}/assign/${assignId}/attendance?isFake=` + isFake, {} )
        .then(html => {
          const newWindow = window.open();
          newWindow.document.open();
          newWindow.document.write(html);
          newWindow.document.close();
        })
  },
  getAssignSheetPopup(assignId) {
    Vue.prototype.$getMethod(`${baseUrl}/assign/${assignId}/sheet`, {} )
        .then(html => {
            const newWindow = window.open();
            newWindow.document.open();
            newWindow.document.write(html);
            newWindow.document.close();
        })
  }
}


export default apiPdf
