var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("a", { staticClass: "btn btn-social mx-2 floating-button" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/icon/kakaotalk_icon.svg"),
            width: "40",
          },
          on: { click: _vm.onButtonClick },
        }),
      ]),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }